<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  dense
                  outlined
                  :label="$t('deduction.name')"
                  v-model="deduction.name"
                  :hint="`${server_errors.name}`"
                  persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
                {{$t('deduction.update')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>

export default {
  props: {
    deduction: {},
  },
  data() {
    return {
      btnLoading: false,
      nameRules: [
        v => !!v || 'field is required...'
      ],
      data: {
        name: "",
      },
      server_errors: {
        name: "",
      }
    }
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    saveChange() {
      this.$axios.put(`company/edit/payroll/deduction/item`, {
        id: this.deduction.id,
        name: this.deduction.name
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.resetForm();
          this.$emit("close");
          this.$emit("success");

        }
        this.btnLoading = false;
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },

}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 0 10px 0 10px;
  margin-right: auto;
  margin-left: auto;

}

.from-content {
  width: 100%;
  height: 100vh;
  //padding: 40px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .from {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

  }

}
</style>
