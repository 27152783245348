<template>
  <nav class="pagination is-centered is-rounded is-small" role="navigation" aria-label="pagination">
    <a class="pagination-previous" @click.prevent="changePage(1)" :disabled="pagination.current_page <= 1">First Page</a>
    <a class="pagination-previous" @click.prevent="changePage(pagination.current_page - 1)" :disabled="pagination.current_page <= 1">Preve</a>
    <a class="pagination-next" @click.prevent="changePage(pagination.current_page + 1)" :disabled="pagination.current_page >= pagination.total_pages">Next</a>
    <a class="pagination-next" @click.prevent="changePage(pagination.per_page)" :disabled="pagination.current_page >= pagination.total_pages">Last Page</a>
    <ul class="pagination-list">
      <li v-for="(page, index) in pages" :key="index">
        <a class="pagination-link" :class="isCurrentPage(page) ? 'is-current' : ''" @click.prevent="changePage(page)">{{ page }}</a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: ['pagination', 'offset'],

  methods: {
    isCurrentPage(page) {
      return this.pagination.current_page === page;
    },
    changePage(page) {
      if (page > this.pagination.total_pages) {
        page = this.pagination.total_pages;
      }
      this.pagination.current_page = page;
      this.$emit('paginate');
    },
  },
  computed: {
    pages() {
      let pages = [];
      let from = this.pagination.current_page - Math.floor(this.offset / 2);

      if (from < 1) {
        from = 1;
      }
      let to = from + this.offset - 1;
      if (to > this.pagination.total_pages) {
        to = this.pagination.total_pages;
      }
      while (from <= to) {
        pages.push(from);
        from++;
      }
      return pages;
    }
  }
}
</script>
