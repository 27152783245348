<template>
  <div class="show-default-table">
    <div class="logo-default-table">
      <h2>
        <span class="lfont"><i class="fal fa-exclamation-circle"></i></span>{{ $t('no_data') }}
      </h2>
      <img :src="require('@/assets/defaultTable.png')" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
