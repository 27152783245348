<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("deduction.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search.search')" v-model="searchItem" @keypress.enter="searchFilterItem">
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">{{ $t("deduction.create") }}</v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listDeduction.length > 0">
          <thead>
            <tr>
              <th class="text-left">{{ $t("deduction.no") }}</th>
              <th class="text-left">{{ $t("deduction.name") }}</th>
              <th class="text-left">{{ $t("deduction.created") }}</th>
              <th class="text-left">{{ $t("deduction.update") }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listDeduction" :key="idx">
              <td>{{ (pagination.current_page * 10) - 10 + idx + 1 }}</td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.created_at }}
              </td>
              <td>
                {{ item.updated_at }}
              </td>
              <td>

              </td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />
        <Pagination class="mt-5 mb-5" v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchDeduction">
        </Pagination>
        <Loading v-if="isLoading" />
        <ModalEdit fullscreen title="Edit Deduction">
          <template v-slot="{ close }">
            <EditDeduction :deduction="deduction" @close="close" @success="fetchDeduction" />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <DeleteDeduction :deductionId="deductionId" @close="close" @success="fetchDeduction" />
          </template>
        </ModalDelete>
      </div>
    </div>
  </div>
</template>

<script>
import EditDeduction from "@/components/Deduction/EditDeduction";
import DeleteDeduction from "@/components/Deduction/DeleteDeduction";
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    EditDeduction,
    Pagination,
    Loading,
    defaultTableNoResult,
    DeleteDeduction
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      listDeduction: [],
      deduction: {},
      deductionId: "",
      searchItem: "",
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchDeduction();
    },
    filterDeduction(deductionId) {
      return (
        this.listDeduction.filter((item) => {
          return item.id == deductionId;
        })[0] || {}
      );
    },

    onCreate() {
      this.$router.push({
        name: "deduction.create",
      }).catch(() => {
      });
    },

    onEdit(deductionId) {
      this.deduction = {
        ...this.filterDeduction(deductionId),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(deductionId) {
      this.deductionId = deductionId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchDeduction() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/payroll/deduction/items`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.isLoading = false;
            this.listDeduction = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listDeduction.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchDeduction();
  },
};
</script>

<style scoped lang="scss"></style>
